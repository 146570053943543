import { useSelector } from 'react-redux';
import '../../assets/scss/components/print2.scss';
import PersonalMatrix from './controls/PersonalMatrix2';
import React, { useMemo } from 'react';
import { AdditionalTable } from './controls/AdditionalTable';
import { Navigator } from './controls/Navigator';
import { DoubleParameter } from './controls/DoubleParameter';
import { SingleParameter } from './controls/SingleParameter';
import { BehaviourRange } from './controls/BehaviourRange';
import { CompatibilityMatrix } from './controls/CompatibilityMatrix';

const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
].map((m) => m.toLowerCase());

export const CompatibilityPrint2 = ({name1, name2, printRef}) => {
    const state = useSelector(state => state.compatibility);
    const visibleName1 = useMemo(() => {
        return `${name1 || 'Дата рождения'} - ${state.date1.getDate()} ${months[state.date1.getMonth()]} ${state.date1.getFullYear()}`
    }, [state.date1, name1])
    const visibleName2 = useMemo(() => {
        return `${name2 || 'Дата рождения'} - ${state.date2.getDate()} ${months[state.date2.getMonth()]} ${state.date2.getFullYear()}`
    }, [state.date2, name2])

    return (
        <div className="print" ref={printRef}>
            <div className="print__page">
                <div className="print__page-row">
                    <div className="print__matrix">
                        <PersonalMatrix values={state.matrixValues1}/>
                    </div>
                    <div className="print__table">
                        <h5 className="print__table--name">
                            {visibleName1}
                        </h5>

                        <AdditionalTable values={state.additionalTableValues1}/>

                        <Navigator values={state.navigatorValues1}/>
                    </div>
                </div>
                <div className="print__page-row">
                    <div className="print__parameter">
                        <DoubleParameter title="Целостность" description="Код принятия себя"
                                         values={state.integrityValues1} names={['Осознать', 'Создать']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Точка духовного зачёта"
                                         description="Сектор максимального результата в жизни"
                                         value={state.innerPointValue1}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="Социализация" description="Код реализации и интеграции в социуме"
                                         values={state.socializationValues1} names={['Стратегии', 'Видение']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Планетарное воздействие"
                                         description="Сверхспособность человека. Уникальный способ влиять на массы"
                                         value={state.planetaryValues1}/>
                    </div>
                </div>
            </div>
            <div className="print__page">
                <div className="print__page-row">
                    <div className="print__matrix">
                        <PersonalMatrix values={state.matrixValues2}/>
                    </div>
                    <div className="print__table">
                        <h5 className="print__table--name">
                            {visibleName2}
                        </h5>

                        <AdditionalTable values={state.additionalTableValues2}/>

                        <Navigator values={state.navigatorValues2}/>
                    </div>
                </div>
                <div className="print__page-row">
                    <div className="print__parameter">
                        <DoubleParameter title="Целостность" description="Код принятия себя"
                                         values={state.integrityValues2} names={['Осознать', 'Создать']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Точка духовного зачёта"
                                         description="Сектор максимального результата в жизни"
                                         value={state.innerPointValue2}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="Социализация" description="Код реализации и интеграции в социуме"
                                         values={state.socializationValues2} names={['Стратегии', 'Видение']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Планетарное воздействие"
                                         description="Сверхспособность человека. Уникальный способ влиять на массы"
                                         value={state.planetaryValues2}/>
                    </div>
                </div>
            </div>
            <div className="print__page">
                <div className="print__page-row">
                    <div className="print__matrix-sm">
                        <CompatibilityMatrix values={state.matrixValues3}/>
                    </div>
                </div>
                <div className="print__page-row">
                    <div className="print__parameter">
                        <DoubleParameter title="Для чего взаимоотношения" description="Что каждый должен осознать и реализовать в паре"
                                         values={state.integrityValues3} names={['Понять', 'Создать']}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="Единство" description="На чем держиться целостность пары"
                                         values={state.unityValues3} names={['', '']}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="Партнёрство" description="Что пара должна создать вместе. Как партнерство помогает реализовываться в социуме."
                                         values={state.socializationValues3} names={['Стратегии', 'Видение']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Планетарное воздействие" description="Сверхспособность пары. Уникальный способ влиять на массы"
                                         value={state.planetaryValues3}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
