import PersonalMatrix from './controls/PersonalMatrix2';
import { Navigator } from './controls/Navigator';
import { BirthdatePicker } from './controls/BirthdatePicker';
import { AdditionalTable } from './controls/AdditionalTable';
import { SingleParameter } from './controls/SingleParameter';
import { DoubleParameter } from './controls/DoubleParameter';
import { useDispatch, useSelector } from 'react-redux';
import { personalSetDate } from '../../store/personal/actions';
import React, { useEffect, useState } from 'react';
import { PersonalPrint2 } from './PersonalPrint2';
import { BehaviourRange } from './controls/BehaviourRange';

export const Personal = ({printRef}) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.personal);

    const [name, setName] = useState('');

    const changeBirthdate = (date) => {
        dispatch(personalSetDate(date));
    };

    useEffect(() => {
        dispatch(personalSetDate(new Date()));
    }, [dispatch]);

    return (
        <div className="container-fluid ps-3 pe-3 ps-lg-5 pe-lg-5" style={{'maxWidth': '1600px'}}>
            <div className="row mt-5">
                <div className="col-12 col-lg-6">
                    <h5 className="mb-3 text-center  print-none">Персональный расчёт</h5>
                    <PersonalMatrix values={state.matrixValues}/>
                </div>
                <div className="col-12 col-lg-6">
                    <BirthdatePicker onChangeValue={changeBirthdate} initialDate={state.date} setName={setName}
                                     name={name}/>
                    <AdditionalTable values={state.additionalTableValues}/>
                    <Navigator values={state.navigatorValues}/>
                </div>
                <div className="col-12 print-col-12">
                    <div className="row mt-1 mt-md-3 mb-1">
                        <div className="col-12 col-sm-6 col-lg-3 p-0 pe-sm-2 pe-lg-1 my-2">
                            <DoubleParameter title="Целостность" description="Код принятия себя"
                                             values={state.integrityValues} names={['Осознать', 'Создать']}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 ps-sm-2 ps-lg-1 pe-lg-1 my-2">
                            <SingleParameter title="Точка духовного зачёта"
                                             description="Сектор максимального результата в жизни"
                                             value={state.innerPointValue}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 pe-sm-2 ps-lg-1 pe-lg-1 my-2">
                            <DoubleParameter title="Социализация" description="Код реализации и интеграции в социуме"
                                             values={state.socializationValues} names={['Стратегии', 'Видение']}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 ps-sm-2 ps-lg-1 my-2">
                            <SingleParameter title="Планетарное воздействие"
                                             description="Сверхспособность человека. Уникальный способ влиять на массы"
                                             value={state.planetaryValues}/>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-0">
                    <BehaviourRange range={state.behaviourRange}/>
                </div>

                <div className="col-12 print-col-12">
                    <div className="row">
                        <div className="col-12 p-0 mt-3">
                            <h5 className="mb-0">Активации личности и потенциала</h5>
                        </div>
                    </div>
                    <div className="row mt-0 mt-md-1 mb-5">
                        <div className="col-12 col-sm-6 col-lg-3 p-0 pe-sm-2 pe-lg-1 my-2">
                            <DoubleParameter title="1 Активация"
                                             values={state.personalityValues[0]}
                                             names={['Что', 'Где']}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 ps-sm-2 ps-lg-1 pe-lg-1 my-2">
                            <DoubleParameter title="2 Активация"
                                             values={state.personalityValues[1]}
                                             names={['Что', 'Где']}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 pe-sm-2 ps-lg-1 pe-lg-1 my-2">
                            <DoubleParameter title="3 Активация"
                                             values={state.personalityValues[2]}
                                             names={['Что', 'Где']}/>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 p-0 ps-sm-2 ps-lg-1 my-2">
                            <DoubleParameter title="4 Активация"
                                             values={state.personalityValues[3]}
                                             names={['Что', 'Где']}/>
                        </div>
                    </div>
                </div>
            </div>

            <PersonalPrint2 printRef={printRef} name={name}/>
        </div>
    );
};
