import React, {useEffect, useState} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {useDispatch, useSelector} from "react-redux";
import {usersSendConfirmation} from "../../store/users/actions";

export const Layout = ({children, printRef}) => {
    const authState = useSelector((state) => state.auth);
    const [confirmModalWasShown, setConfirmModalWasShown] = useState(true);
    const [showWasSent, setShowWasSent] = useState(false);

    const dispatch = useDispatch();

    const sendEmail = (e) => {
        e.preventDefault();
        dispatch(usersSendConfirmation());
        setConfirmModalWasShown(true);
        setShowWasSent(true);
    }

    useEffect(() => {
        if(authState.authorized && !authState.emailConfirmed) {
            setConfirmModalWasShown(false);
        }
    }, [authState.authorized, authState.emailConfirmed])

    return (
        <>
            <Header printRef={printRef}/>
            {children}
            <Footer/>

            <div className="modal" style={{display: confirmModalWasShown ? 'none' : 'block'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Адрес электронной почты не подтверждён</h5>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Закрыть"
								onClick={() => setConfirmModalWasShown(true)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>Нажмите на кнопку ниже, чтобы получить ссылку на подтверждение вашего аккаунта</p>
                        </div>
                        <div className="modal-footer">
                            <button
								type="button"
								className="btn btn-secondary"
								onClick={() => setConfirmModalWasShown(true)}
							>
                                Закрыть
                            </button>
                            <button type="button" className="btn btn-primary text-light" onClick={sendEmail}>Выслать письмо
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal" style={{display: showWasSent ? 'block' : 'none'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Готово</h5>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Закрыть"
                                onClick={() => setShowWasSent(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>Ссылка для подтверждения адреса электронной почты была выслана на наш e-mail</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setShowWasSent(false)}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
