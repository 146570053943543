import React from "react";
import pBackgroundSvg from "../../../assets/images/p-background-new.svg";

function Icon({values}) {
    return (
        <div className="row">
            <div className="col-12 mt-3 position-relative">
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 20 324 284'
                >
                    <image
                        x="0"
                        y="0"
                        width='324'
                        height='324'
                        xlinkHref={pBackgroundSvg}
                    ></image>
                    <text
                        x='161.916'
                        y='126.776'
                        fill='#fff'
                        strokeWidth='0.268'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='161.916' y='126.776' strokeWidth='0.268'>{values[25]}</tspan>
                    </text>
                    <text
                        x='35.44'
                        y='144.769'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='35.44' y='144.769' strokeWidth='0.268'>{values[30]}</tspan>
                    </text>
                    <text
                        x='38.245'
                        y='138.079'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='38.245' y='138.079' strokeWidth='0.268'>{values[31]}</tspan>
                    </text>
                    <text
                        x='41.05'
                        y='131.39'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='41.05' y='131.39' strokeWidth='0.268'>{values[32]}</tspan>
                    </text>
                    <text
                        x='43.855'
                        y='124.701'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='43.855' y='124.701' strokeWidth='0.268'>{values[86]}</tspan>
                    </text>
                    <text
                        x='57.878'
                        y='91.254'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='57.878' y='91.254' strokeWidth='0.268'>{values[87]}</tspan>
                    </text>
                    <text
                        x='46.659'
                        y='118.011'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='46.659' y='118.011' strokeWidth='0.268'>{values[33]}</tspan>
                    </text>
                    <text
                        x='49.464'
                        y='111.322'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='49.464' y='111.322' strokeWidth='0.268'>{values[34]}</tspan>
                    </text>
                    <text
                        x='52.269'
                        y='104.633'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='52.269' y='104.633' strokeWidth='0.268'>{values[35]}</tspan>
                    </text>
                    <text
                        x='55.074'
                        y='97.943'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='55.074' y='97.943' strokeWidth='0.268'>{values[36]}</tspan>
                    </text>
                    <text
                        x='92.773'
                        y='62.206'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='92.773' y='62.206' strokeWidth='0.268'>{values[37]}</tspan>
                    </text>
                    <text
                        x='99.52'
                        y='59.449'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='99.52' y='59.449' strokeWidth='0.268'>{values[38]}</tspan>
                    </text>
                    <text
                        x='113.014'
                        y='53.936'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='113.014' y='53.936' strokeWidth='0.268'>{values[88]}</tspan>
                    </text>
                    <text
                        x='119.761'
                        y='51.179'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='119.761' y='51.179' strokeWidth='0.268'>{values[40]}</tspan>
                    </text>
                    <text
                        x='126.508'
                        y='48.422'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='126.508' y='48.422' strokeWidth='0.268'>{values[41]}</tspan>
                    </text>
                    <text
                        x='133.255'
                        y='45.666'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='133.255' y='45.666' strokeWidth='0.268'>{values[42]}</tspan>
                    </text>
                    <text
                        x='140.001'
                        y='42.909'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='140.001' y='42.909' strokeWidth='0.268'>{values[43]}</tspan>
                    </text>
                    <text
                        x='146.748'
                        y='40.152'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='146.748' y='40.152' strokeWidth='0.268'>{values[89]}</tspan>
                    </text>
                    <text
                        x='106.267'
                        y='56.693'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='106.267' y='56.693' strokeWidth='0.268'>{values[39]}</tspan>
                    </text>
                    <text
                        x='222.588'
                        y='57.551'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='222.588' y='57.551' strokeWidth='0.268'>{values[49]}</tspan>
                    </text>
                    <text
                        x='235.992'
                        y='63.043'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='235.992' y='63.043' strokeWidth='0.268'>{values[91]}</tspan>
                    </text>
                    <text
                        x='229.29'
                        y='60.297'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='229.29' y='60.297' strokeWidth='0.268'>{values[50]}</tspan>
                    </text>
                    <text
                        x='215.886'
                        y='54.805'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='215.886' y='54.805' strokeWidth='0.268'>{values[48]}</tspan>
                    </text>
                    <text
                        x='209.184'
                        y='52.06'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='209.184' y='52.06' strokeWidth='0.268'>{values[47]}</tspan>
                    </text>
                    <text
                        x='202.482'
                        y='49.314'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='202.482' y='49.314' strokeWidth='0.268'>{values[90]}</tspan>
                    </text>
                    <text
                        x='195.78'
                        y='46.568'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='195.78' y='46.568' strokeWidth='0.268'>{values[46]}</tspan>
                    </text>
                    <text
                        x='189.078'
                        y='43.822'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='189.078' y='43.822' strokeWidth='0.268'>{values[45]}</tspan>
                    </text>
                    <text
                        x='182.376'
                        y='41.076'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='182.376' y='41.076' strokeWidth='0.268'>{values[44]}</tspan>
                    </text>
                    <text
                        x='281.341'
                        y='137.993'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='281.341' y='137.993' strokeWidth='0.268'>{values[57]}</tspan>
                    </text>
                    <text
                        x='284.298'
                        y='180.211'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='284.298' y='180.211' strokeWidth='0.268'>{values[58]}</tspan>
                    </text>
                    <text
                        x='281.53'
                        y='186.891'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='281.53' y='186.891' strokeWidth='0.268'>{values[59]}</tspan>
                    </text>
                    <text
                        x='278.762'
                        y='193.571'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='278.762' y='193.571' strokeWidth='0.268'>{values[60]}</tspan>
                    </text>
                    <text
                        x='275.994'
                        y='200.251'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='275.994' y='200.251' strokeWidth='0.268'>{values[94]}</tspan>
                    </text>
                    <text
                        x='273.226'
                        y='206.931'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='273.226' y='206.931' strokeWidth='0.268'>{values[61]}</tspan>
                    </text>
                    <text
                        x='270.458'
                        y='213.611'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='270.458' y='213.611' strokeWidth='0.268'>{values[62]}</tspan>
                    </text>
                    <text
                        x='267.69'
                        y='220.291'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='267.69' y='220.291' strokeWidth='0.268'>{values[63]}</tspan>
                    </text>
                    <text
                        x='264.922'
                        y='226.971'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='264.922' y='226.971' strokeWidth='0.268'>{values[64]}</tspan>
                    </text>
                    <text
                        x='262.154'
                        y='233.651'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='262.154' y='233.651' strokeWidth='0.268'>{values[95]}</tspan>
                    </text>
                    <text
                        x='179.959'
                        y='286.033'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='179.959' y='286.033' strokeWidth='0.268'>{values[97]}</tspan>
                    </text>
                    <text
                        x='186.708'
                        y='283.307'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='186.708' y='283.307' strokeWidth='0.268'>{values[71]}</tspan>
                    </text>
                    <text
                        x='193.457'
                        y='280.581'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='193.457' y='280.581' strokeWidth='0.268'>{values[70]}</tspan>
                    </text>
                    <text
                        x='200.206'
                        y='277.855'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='200.206' y='277.855' strokeWidth='0.268'>{values[69]}</tspan>
                    </text>
                    <text
                        x='206.954'
                        y='275.129'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='206.954' y='275.129' strokeWidth='0.268'>{values[68]}</tspan>
                    </text>
                    <text
                        x='213.703'
                        y='272.403'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='213.703' y='272.403' strokeWidth='0.268'>{values[96]}</tspan>
                    </text>
                    <text
                        x='220.452'
                        y='269.677'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='220.452' y='269.677' strokeWidth='0.268'>{values[67]}</tspan>
                    </text>
                    <text
                        x='227.201'
                        y='266.951'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='227.201' y='266.951' strokeWidth='0.268'>{values[66]}</tspan>
                    </text>
                    <text
                        x='233.949'
                        y='264.225'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='233.949' y='264.225' strokeWidth='0.268'>{values[65]}</tspan>
                    </text>
                    <text
                        x='104.043'
                        y='269.62'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='104.043' y='269.62' strokeWidth='0.268'>{values[77]}</tspan>
                    </text>
                    <text
                        x='90.575'
                        y='264.1'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='90.575' y='264.1' strokeWidth='0.268'>{values[99]}</tspan>
                    </text>
                    <text
                        x='97.309'
                        y='266.86'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='97.309' y='266.86' strokeWidth='0.268'>{values[78]}</tspan>
                    </text>
                    <text
                        x='110.777'
                        y='272.38'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='110.777' y='272.38' strokeWidth='0.268'>{values[76]}</tspan>
                    </text>
                    <text
                        x='117.511'
                        y='275.14'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='117.511' y='275.14' strokeWidth='0.268'>{values[75]}</tspan>
                    </text>
                    <text
                        x='124.245'
                        y='277.901'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='124.245' y='277.901' strokeWidth='0.268'>{values[98]}</tspan>
                    </text>
                    <text
                        x='130.979'
                        y='280.661'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='130.979' y='280.661' strokeWidth='0.268'>{values[74]}</tspan>
                    </text>
                    <text
                        x='137.713'
                        y='283.421'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='137.713' y='283.421' strokeWidth='0.268'>{values[73]}</tspan>
                    </text>
                    <text
                        x='144.447'
                        y='286.181'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                        textAnchor='middle'
                    >
                        <tspan x='144.447' y='286.181' strokeWidth='0.268'>{values[72]}</tspan>
                    </text>
                    <text
                        x='35.86'
                        y='180.088'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='35.86' y='180.088' strokeWidth='0.268'>{values[101]}</tspan>
                    </text>
                    <text
                        x='38.627'
                        y='186.793'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='38.627' y='186.793' strokeWidth='0.268'>{values[85]}</tspan>
                    </text>
                    <text
                        x='41.393'
                        y='193.499'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='41.393' y='193.499' strokeWidth='0.268'>{values[84]}</tspan>
                    </text>
                    <text
                        x='44.16'
                        y='200.204'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='44.16' y='200.204' strokeWidth='0.268'>{values[83]}</tspan>
                    </text>
                    <text
                        x='46.926'
                        y='206.91'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='46.926' y='206.91' strokeWidth='0.268'>{values[82]}</tspan>
                    </text>
                    <text
                        x='49.693'
                        y='213.616'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='49.693' y='213.616' strokeWidth='0.268'>{values[100]}</tspan>
                    </text>
                    <text
                        x='52.459'
                        y='220.321'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='52.459' y='220.321' strokeWidth='0.268'>{values[81]}</tspan>
                    </text>
                    <text
                        x='55.226'
                        y='227.027'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='55.226' y='227.027' strokeWidth='0.268'>{values[80]}</tspan>
                    </text>
                    <text
                        x='57.993'
                        y='233.732'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='57.993' y='233.732' strokeWidth='0.268'>{values[79]}</tspan>
                    </text>
                    <text
                        x='49.687'
                        y='165.293'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='49.687' y='165.293' strokeWidth='0.268'>{values[0]}</tspan>
                    </text>
                    <text
                        x='162.28'
                        y='52.938'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='162.28' y='52.938' strokeWidth='0.268'>{values[2]}</tspan>
                    </text>
                    <text
                        x='273.457'
                        y='164.808'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='273.457' y='164.808' strokeWidth='0.268'>{values[4]}</tspan>
                    </text>
                    <text
                        x='161.51'
                        y='276.992'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='161.51' y='276.992' strokeWidth='0.268'>{values[6]}</tspan>
                    </text>
                    <text
                        x='82.58'
                        y='244.061'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='82.58' y='244.061' strokeWidth='0.268'>{values[7]}</tspan>
                    </text>
                    <text
                        x='82.765'
                        y='85.92'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='82.765' y='85.92' strokeWidth='0.268'>{values[1]}</tspan>
                    </text>
                    <text
                        x='241.402'
                        y='85.887'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='241.402' y='85.887' strokeWidth='0.268'>{values[3]}</tspan>
                    </text>
                    <text
                        x='241.582'
                        y='244.104'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='241.582' y='244.104' strokeWidth='0.268'>{values[5]}</tspan>
                    </text>
                    <text
                        x='162.28'
                        y='166.309'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='10.005'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='162.28' y='166.309' strokeWidth='0.268'>{values[29]}</tspan>
                    </text>
                    <text
                        x='72.198'
                        y='164.671'
                        fill='#fff'
                        strokeWidth='0.268'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='72.198' y='164.671' strokeWidth='0.268'>{values[8]}</tspan>
                    </text>
                    <text
                        x='162.257'
                        y='74.285'
                        fill='#fff'
                        strokeWidth='0.268'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='162.257' y='74.285' strokeWidth='0.268'>{values[10]}</tspan>
                    </text>
                    <text
                        x='252.176'
                        y='164.671'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='252.176' y='164.671' strokeWidth='0.268'>{values[12]}</tspan>
                    </text>
                    <text
                        x='161.95'
                        y='253.967'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='161.95' y='253.967' strokeWidth='0.268'>{values[14]}</tspan>
                    </text>
                    <text
                        x='225.773'
                        y='227.803'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='225.773' y='227.803' strokeWidth='0.268'>{values[13]}</tspan>
                    </text>
                    <text
                        x='98.652'
                        y='227.666'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='98.652' y='227.666' strokeWidth='0.268'>{values[15]}</tspan>
                    </text>
                    <text
                        x='98.376'
                        y='100.79'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='98.376' y='100.79' strokeWidth='0.268'>{values[9]}</tspan>
                    </text>
                    <text
                        x='225.842'
                        y='100.661'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='7.861'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='225.842' y='100.661' strokeWidth='0.268'>{values[11]}</tspan>
                    </text>
                    <text
                        x='108.783'
                        y='110.703'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontWeight='bold'
                    >
                        <tspan x='108.783' y='110.703' strokeWidth='0.268'>{values[17]}</tspan>
                    </text>
                    <text
                        x='216.042'
                        y='110.574'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontWeight='bold'
                    >
                        <tspan x='216.042' y='110.574' strokeWidth='0.268'>{values[19]}</tspan>
                    </text>
                    <text
                        x='237.846'
                        y='164.163'
                        fill='#fff'
                        strokeWidth='0.268'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='237.846' y='164.163' strokeWidth='0.268'>{values[20]}</tspan>
                    </text>
                    <text
                        x='215.336'
                        y='217.085'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='215.336' y='217.085' strokeWidth='0.268'>{values[21]}</tspan>
                    </text>
                    <text
                        x='213.771'
                        y='192.019'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='213.771' y='192.019' strokeWidth='0.268'>{values[26]}</tspan>
                    </text>
                    <text
                        x='193.925'
                        y='196.105'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='193.925' y='196.105' strokeWidth='0.268'>{values[27]}</tspan>
                    </text>
                    <text
                        x='190.957'
                        y='214.511'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='190.957' y='214.511' strokeWidth='0.268'>{values[28]}</tspan>
                    </text>
                    <text
                        x='161.934'
                        y='238.872'
                        fill='#fff'
                        strokeWidth='0.268'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='161.934' y='238.872' strokeWidth='0.268'>{values[22]}</tspan>
                    </text>
                    <text
                        x='109.058'
                        y='216.734'
                        strokeWidth='0.268'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fontFamily='Montserrat'
                    >
                        <tspan x='109.058' y='216.734' strokeWidth='0.268'>{values[23]}</tspan>
                    </text>
                    <text
                        x='86.498'
                        y='164.163'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='86.498' y='164.163' strokeWidth='0.268'>{values[16]}</tspan>
                    </text>
                    <text
                        x='124.37'
                        y='164.163'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='124.37' y='164.163' strokeWidth='0.268'>{values[24]}</tspan>
                    </text>
                    <text
                        x='162.242'
                        y='88.516'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        fill='#fff'
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='6.432'
                        fontWeight='bold'
                        textAnchor='middle'
                    >
                        <tspan x='162.242' y='88.516' strokeWidth='0.268'>{values[18]}</tspan>
                    </text>
                    <text
                        x='284.156'
                        y='144.68'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='284.156' y='144.68' strokeWidth='0.268'>{values[93]}</tspan>
                    </text>
                    <text
                        x='278.526'
                        y='131.306'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='278.526' y='131.306' strokeWidth='0.268'>{values[56]}</tspan>
                    </text>
                    <text
                        x='275.711'
                        y='124.619'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='275.711' y='124.619' strokeWidth='0.268'>{values[55]}</tspan>
                    </text>
                    <text
                        x='272.896'
                        y='117.932'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='272.896' y='117.932' strokeWidth='0.268'>{values[54]}</tspan>
                    </text>
                    <text
                        x='270.081'
                        y='111.245'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='270.081' y='111.245' strokeWidth='0.268'>{values[92]}</tspan>
                    </text>
                    <text
                        x='267.266'
                        y='104.558'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='267.266' y='104.558' strokeWidth='0.268'>{values[53]}</tspan>
                    </text>
                    <text
                        x='264.451'
                        y='97.871'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='264.451' y='97.871' strokeWidth='0.268'>{values[52]}</tspan>
                    </text>
                    <text
                        x='261.637'
                        y='91.184'
                        transform='scale(.99808 1.00192)'
                        style={{lineHeight: "1.25"}}
                        strokeWidth='0.268'
                        fontFamily='Montserrat'
                        fontSize='5.003'
                        fontWeight='600'
                    >
                        <tspan x='261.637' y='91.184' strokeWidth='0.268'>{values[51]}</tspan>
                    </text>
                </svg>
            </div>
        </div>
    );
}

export default Icon;