import { useSelector } from 'react-redux';
import '../../assets/scss/components/print2.scss';
import PersonalMatrix from './controls/PersonalMatrix2';
import React, { useMemo } from 'react';
import { AdditionalTable } from './controls/AdditionalTable';
import { Navigator } from './controls/Navigator';
import { DoubleParameter } from './controls/DoubleParameter';
import { SingleParameter } from './controls/SingleParameter';
import { BehaviourRange } from './controls/BehaviourRange';

export const PersonalPrint2 = ({name, printRef}) => {
    const state = useSelector(state => state.personal);
    const visibleName = useMemo(() => {
        const months = [
            'Января',
            'Февраля',
            'Марта',
            'Апреля',
            'Мая',
            'Июня',
            'Июля',
            'Августа',
            'Сентября',
            'Октября',
            'Ноября',
            'Декабря',
        ].map((m) => m.toLowerCase());

        return `${name || 'Дата рождения'} - ${state.date.getDate()} ${months[state.date.getMonth()]} ${state.date.getFullYear()}`
    }, [state.date, name])

    return (
        <div className="print" ref={printRef}>
            <div className="print__page">
                <div className="print__page-row">
                    <div className="print__matrix">
                        <PersonalMatrix values={state.matrixValues}/>
                    </div>
                    <div className="print__table">
                        <h5 className="print__table--name">
                            {visibleName}
                        </h5>

                        <AdditionalTable values={state.additionalTableValues}/>

                        <Navigator values={state.navigatorValues}/>
                    </div>
                </div>
                <div className="print__page-row">
                    <div className="print__parameter">
                        <DoubleParameter title="Целостность" description="Код принятия себя"
                                         values={state.integrityValues} names={['Осознать', 'Создать']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Точка духовного зачёта"
                                         description="Сектор максимального результата в жизни"
                                         value={state.innerPointValue}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="Социализация" description="Код реализации и интеграции в социуме"
                                         values={state.socializationValues} names={['Стратегии', 'Видение']}/>
                    </div>
                    <div className="print__parameter">
                        <SingleParameter title="Планетарное воздействие"
                                         description="Сверхспособность человека. Уникальный способ влиять на массы"
                                         value={state.planetaryValues}/>
                    </div>
                </div>
            </div>

            <div className="print__page">
                <div className="print__page-row">
                    <div className="print__behaviour">
                        <BehaviourRange range={state.behaviourRange}/>
                    </div>
                </div>
                <div className="print__page-row">
                    <h5>Активации личности и потенциала</h5>
                </div>
                <div className="print__page-row">
                    <div className="print__parameter">
                        <DoubleParameter title="1 Активация"
                                         values={state.personalityValues[0]}
                                         names={['Что', 'Где']}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="2 Активация"
                                         values={state.personalityValues[1]}
                                         names={['Что', 'Где']}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="3 Активация"
                                         values={state.personalityValues[2]}
                                         names={['Что', 'Где']}/>
                    </div>
                    <div className="print__parameter">
                        <DoubleParameter title="4 Активация"
                                         values={state.personalityValues[3]}
                                         names={['Что', 'Где']}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
